<!--
 * @Description: 我的流程
 * @Author: 琢磨先生
 * @Date: 2022-09-21 14:46:51
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-28 00:57:41
-->

<template>
  <el-card class="box">
    <el-form ref="query" :model="query" :inline="true">
      <el-form-item label="类型">
        <el-cascader
          :options="options"
          v-model="query.templateIds"
          clearable
          :props="props"
          @change="queryTemplateChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="query.status" placeholder clearable style="width:150px;">
          <el-option
            :label="item.text"
            :value="item.value"
            :key="item.value"
            v-for="item in enums.flow_audit_status"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-popover
      placement="bottom-start"
      title
      :width="400"
      trigger="click"
      content
      :visible="popoverVisible"
    >
      <template #reference>
        <el-button type="primary" icon="Plus" size="small" round @click="popoverVisible = true">新增流程</el-button>
      </template>
      <el-cascader-panel
        ref="cascaderPanel"
        :options="options"
        @change="templateChange"
        :props="props"
      />
    </el-popover>
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData.data" border @sort-change="onSort">
      <el-table-column label="类型" prop="template.name" width="180"></el-table-column>
      <el-table-column label="标题" prop="title"></el-table-column>
      <el-table-column label="状态" prop="status_text" width="180"></el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onShowDtl(scope.row)">查看</el-button>
          <el-button type="primary" link size="small" @click="onEdit(scope.row)">撤回</el-button>
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <edit-audit :template="template" @close="template = null" @success="onSuccess"></edit-audit>

  <el-drawer
    title="查看详情"
    v-model="visibleDtlDrawer"
    size="1000px"
    :destroy-on-close="true"
    :show-close="true"
  >
    <el-tabs>
      <el-tab-pane label="流程详情">
        <el-form label-width="100px">
          <el-form-item :label="`${item.label}:`" v-for="item in dtl.form_content_list" :key="item">
            <span v-if="item.element!='file'">{{item.value}}</span>
            <div v-if="item.element=='file'">
              <div v-for="f in item.files" :key="f">
                <span>{{f.name}}</span>
                <el-link :underline="false" type="primary" :href="f.file_url">下载</el-link>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="流程审批">流程审批</el-tab-pane>
      <el-tab-pane label="审批记录">审批记录</el-tab-pane>
      <!-- <el-tab-pane label="Task">Task</el-tab-pane> -->
    </el-tabs>
  </el-drawer>
</template>

<script>
import flow_api from "@/http/flow_api";
import EditAudit from "./edit_audit.vue";
import common_api from "@/http/common_api";
export default {
  components: {
    EditAudit,
  },
  data() {
    return {
      options: [],
      props: {
        expandTrigger: "hover",
      },
      enums: {},
      popoverVisible: false,
      template: null,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
        status: "",
      },
      tableData: {
        counts: 0,
      },

      // 详情
      visibleDtlDrawer: false,
      dtl: {},
    };
  },
  created() {
    flow_api.get_templates().then((res) => {
      if (res.code == 0) {
        this.options = [];
        this.template_list = res.data;
        res.data.forEach((item) => {
          var model = {
            value: item.id,
            label: item.name,
            children: [],
          };
          item.templates.forEach((x) => {
            model.children.push({
              label: x.name,
              value: x.id,
            });
          });
          this.options.push(model);
        });
      }
    });
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    this.loadData();
  },
  methods: {
    /**
     * 选择模板更改
     */
    templateChange(values) {
      if (values) {
        var item = this.template_list.find((o) => o.id == values[0]);
        this.template = item.templates.find((x) => x.id == values[1]);
        this.$refs.cascaderPanel.clearCheckedNodes();
        this.popoverVisible = false;
      }
    },

    /**
     * 搜索模板列表选择更改
     */
    queryTemplateChange(values) {
      if (values) {
        this.query.template_id = values[values.length - 1];
      } else {
        this.query.template_id = "";
      }
    },

    /**
     * 添加成功
     */
    onSuccess() {
      this.loadData();
    },

    /**
     * 搜索数据
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/workFlow/my", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     * 查看详情
     */
    onShowDtl(item) {
      this.visibleDtlDrawer = true;
      this.dtlLoading = true;
      this.$http.get("seller/v1/workFlow/dtl?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.dtl = res.data;
        }
        this.dtlLoading = false;
      });
    },
  },
};
</script>

<style scoped></style>
