/*
 * @Description: 流程api
 * @Author: 琢磨先生
 * @Date: 2022-09-24 09:23:10
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-24 12:35:34
 */

import http from "./axios_init.js";

export default {
    /**
     * 获取流程模板
     * @returns
     */
    get_templates() {
      return http.get("seller/v1/flowTemplate");
    },
   
  
    
  };
  